import React, { useEffect } from "react"
import picturefill from "picturefill"

import Layout from "../components/layout"
import SEO from "../components/seo"

import limpiaduriaGrupo from "../images/limpiaduria-grupo.jpg"
import limpiaduriaGrupoMini from "../images/limpiaduria-grupo-mini.jpg"

const IndexPage = () => {
  useEffect(() => {
    picturefill()
  })

  return (
    <Layout>
      <SEO
        title="Contacto"
        description="En Limpiaduria huizar estamos para servirte, conoce aquí nuestros medios de contato."
      />
      <div className="mc-wrapper-2 extra-padding">
        <div className="mc-wrapper-3">
          <h1 className="title">Contacto</h1>
          <div className="mc-wrapper-4">
            <picture>
              <source
                srcSet={limpiaduriaGrupo}
                media="(min-width: 900px)"
                alt="limpiaduria huizar grupo"
              />
              <img
                srcSet={limpiaduriaGrupoMini}
                alt="limpiaduria huizar grupo mini"
              />
            </picture>
            <div className="contact-col-group clearfix contact-list">
              <div className="column one">
                <div className="value">(686) 552 80 25</div>
                <div className="type">Atencion a clientes</div>
              </div>
              <div className="column two">
                <div className="value">(686) 553 63 20</div>
                <div className="type">Administracion</div>
              </div>
              <div className="column three">
                <div className="value mini">contacto@huizar.mx</div>
                <div className="type">Correo Electronico</div>
              </div>
              <div className="column four">
                <div className="value mini">
                  Av. Zaragoza #2094
                  <br />
                  col. Nueva Mexicali B.C.
                </div>
                <div className="type">Direccion</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
